import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getScoreRuleNameList, getConfigurableStructure, editRuleStructureMap } from '@/api/gradeRules';
export default {
  props: {
    Visibles: {
      type: Boolean,
      default: false
    },
    structureDetail: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    dialogTitles: {
      type: String,
      default: '新增得分部门'
    }
  },
  watch: {
    structureDetail: {
      deep: true,
      //深度监听
      handler: function handler(val) {
        // console.log('structure_ids', val.structure_names);
        if (val.structure_ids) {
          var idArr = val.structure_ids.split(',');
          var nameArr = val.structure_names.split(',');
          var arr = [];
          this.structureSelectedData = [];
          for (var i = 0; i < idArr.length; i++) {
            arr.push([idArr[i]]);
            this.structureSelectedData.push({
              id: idArr[i],
              name: nameArr[i]
            });
          }
          this.options = this.options.concat(this.structureSelectedData);
          this.form.structure_ids = arr;
        }
        this.form.rule_id = val.rule_id;
        this.form.dataId = val.id;
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      form: {
        structure_ids: '',
        rule_id: '',
        dataId: ''
      },
      rules: {
        rule_id: [{
          required: true,
          message: '请选择规则',
          trigger: 'change'
        }],
        structure_ids: [{
          required: true,
          message: '请选择部门',
          trigger: 'change'
        }]
      },
      getRulesList: [],
      props: {
        multiple: true,
        value: 'id',
        label: 'name'
      },
      options: [],
      // 编辑的时候已选择的数据
      structureSelectedData: [],
      // 按钮的loading状态
      loadingSubmit: false
    };
  },
  computed: {
    dialogFormVisible: {
      get: function get() {
        this.openDialogFun();
        return this.Visibles;
      },
      set: function set(newV) {
        this.$emit('update:visibles', newV);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    openDialogFun: function openDialogFun() {
      console.log('重新加载了');
      this.form = {
        structure_ids: '',
        rule_id: '',
        dataId: ''
      };
      this.getRulesListFun();
      this.getStructureListFun();
    },
    cancel: function cancel() {
      this.dialogTableVisible = false;
    },
    // 获取规则列表
    getRulesListFun: function getRulesListFun() {
      var _this = this;
      getScoreRuleNameList().then(function (respomse) {
        _this.getRulesList = respomse.data;
      });
    },
    // 获取可配置部门列表
    getStructureListFun: function getStructureListFun() {
      var _this2 = this;
      getConfigurableStructure().then(function (respomse) {
        // console.log('respomse.data', respomse);
        _this2.options = respomse.data;
        // console.log('this.structureSelectedData', this.structureSelectedData);
        _this2.options = _this2.options.concat(_this2.structureSelectedData);
      });
    },
    // 新建方法
    addDataFun: function addDataFun(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.loadingSubmit = true;
          var params = {
            structure_ids: _this3.form.structure_ids.flat().toString(),
            rule_id: _this3.form.rule_id
          };
          if (_this3.form.dataId) {
            params.id = _this3.form.dataId;
          }
          editRuleStructureMap(params).then(function (respomse) {
            // console.log('respomse.data', respomse);
            // this.options = respomse.data;
            _this3.$emit('queryClick');
            _this3.$emit('update:visibles', false);
          }).finally(function () {
            _this3.loadingSubmit = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};