//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import StaffSelect from '@/components/StaffSelect/StaffSelectone';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import Popup from "./dialogScore";
import { getPromotionStructure, getRuleStructureMapList, removeRuleStructureMap, getRuleStructureMapInfo } from '@/api/gradeRules';
export default {
  name: 'outbound',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '参与得分配置',
      mailListVisibleEs: false,
      popup: {
        Titles: '新增得分部门',
        department: [],
        rulese: []
      },
      value: '',
      options: [],
      tableData: [],
      structure_id: '',
      // 得分规则列表
      getRulesList: [],
      structureDetail: {},
      dialogTitles: '新增得分部门'
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {} else {}
    this.queryClick();
    this.selectDataFun();
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs,
    StaffSelect: StaffSelect,
    Popup: Popup
  },
  methods: {
    queryClick: function queryClick() {
      var _this = this;
      //列表数据
      getRuleStructureMapList({
        structure_id: this.structure_id
      }).then(function (respomse) {
        _this.tableData = respomse.data;
      });
    },
    ModalBox: function ModalBox() {
      this.dialogTitles = '新增得分部门';
      //新增、读取详情
      this.mailListVisibleEs = true;
    },
    selectChanged: function selectChanged() {},
    // 编辑事件
    dataMethodFun: function dataMethodFun(index, id, row) {
      this.mailListVisibleEs = true;
      this.dialogTitles = '编辑得分部门';
      if (index == 1) {
        this.getDetailDataFun(id, row.structure_names);
      }
    },
    // 详情接口
    getDetailDataFun: function getDetailDataFun(id, structure_names) {
      var _this2 = this;
      getRuleStructureMapInfo({
        id: id
      }).then(function (respomse) {
        _this2.structureDetail = respomse.data;
        _this2.$set(_this2.structureDetail, 'structure_names', structure_names);
        console.log('structureDetail', respomse);
      });
    },
    // 删除事件
    deleteDataFun: function deleteDataFun(id) {
      var _this3 = this;
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        removeRuleStructureMap({
          id: id
        }).then(function (respomse) {
          _this3.$message({
            message: '删除成功',
            type: 'success'
          });
          _this3.queryClick();
        });
      }).catch(function () {});
    },
    // 所属部门下拉数据
    selectDataFun: function selectDataFun() {
      var _this4 = this;
      getPromotionStructure().then(function (respomse) {
        _this4.options = respomse.data;
      });
    }
  }
};